import {UtilsMisc} from '../../utils/utils.misc';

export enum SystemEnvironment {

    Development = 0,
    DevelopmentTest = 1,
    Test = 2,
    Production = 3

}

export interface IConfigSystem {

    environment: SystemEnvironment;

}

// @dynamic
export class ConfigSystem {

    private static _environment: SystemEnvironment = SystemEnvironment.Development;

    public static get environment(): SystemEnvironment {
        return ConfigSystem._environment;
    }

    public static assign(source: IConfigSystem): void {
        if (UtilsMisc.isNullOrUndefinedOrEmpty(source) === false) {
            ConfigSystem._environment = source.environment;
        }
    }

}
