import {Provider} from '@angular/core';
import {ApplicationService, ErrorService} from 'src/core/pin.core';
import {PITTrackWebApplicationService} from '../services/implementations/pit.track.web.application.service';
import {PITTrackWebErrorService} from '../services/implementations/pit.track.web.error.service';

export const pitTrackWebCoreProviders: Provider[] = [

    {
        provide: ApplicationService,
        useClass: PITTrackWebApplicationService
    },
    {
        provide: ErrorService,
        useClass: PITTrackWebErrorService
    },

];
