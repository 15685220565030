import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';
import {PinCoreModule} from 'src/core/pin.core';
import {PinSignalRModule} from 'src/core/pin.signalr';
import {PITTrackWebShowErrorPageComponent} from '../pages/pit.track.web.show.error.page/pit.track.web.show.error.page.component';
import {pitTrackWebCoreProviders} from '../providers/pit.track.web.core.providers';

@NgModule({
    declarations: [
        PITTrackWebShowErrorPageComponent
    ],
    entryComponents: [],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,

        PinCoreModule,
        PinSignalRModule
    ],
    exports: [],
    providers: [
        //...pitTrackCoreProviders
    ]
})
export class PITTrackWebCoreModule {

    constructor() {
        console.log('PITTrackWebCoreModule');
    }

    public static forRoot(): ModuleWithProviders<PITTrackWebCoreModule> {
        return {
            ngModule: PITTrackWebCoreModule,
            providers: [
                ...pitTrackWebCoreProviders
            ]
        };
    }

}
