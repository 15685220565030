import {ModelEntity} from '../implementations/model.entity';
import {ICredentialsEntity, IUserProfileEntity} from './authentication.interfaces';

export class UserProfileEntity extends ModelEntity implements IUserProfileEntity {

    constructor(initValues: Partial<IUserProfileEntity> = null) {
        super();

        if (initValues !== null) {
            Object.assign(this, initValues);
        }
    }

    protected _email: string = null;

    public get email(): string {
        return this._email;
    }

    public set email(value: string) {
        this._email = value;
    }

    protected _firstName: string = null;

    public get firstName(): string {
        return this._firstName;
    }

    public set firstName(value: string) {
        this._firstName = value;
    }

    protected _lastName: string = null;

    public get lastName(): string {
        return this._lastName;
    }

    public set lastName(value: string) {
        this._lastName = value;
    }

    protected _phoneMobile: string = null;

    public get phoneMobile(): string {
        return this._phoneMobile;
    }

    public set phoneMobile(value: string) {
        this._phoneMobile = value;
    }

    protected _phonePrivate: string = null;

    public get phonePrivate(): string {
        return this._phonePrivate;
    }

    public set phonePrivate(value: string) {
        this._phonePrivate = value;
    }

    protected _phoneWork: string = null;

    public get phoneWork(): string {
        return this._phoneWork;
    }

    public set phoneWork(value: string) {
        this._phoneWork = value;
    }

    protected _credentials: ICredentialsEntity = null;

    public get credentials(): ICredentialsEntity {
        return this._credentials;
    }

    public set credentials(value: ICredentialsEntity) {
        this._credentials = value;
    }

}
