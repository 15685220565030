import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ILoggingService} from '../../logging/logging.service';
import {IRoutingService} from '../../services/misc/routing.service';
import {AuthenticationStatus, IAuthenticationService} from '../services/authentication.service';

export interface IAuthenticationGuard extends CanActivate {

}

export class AuthenticationGuardBase implements IAuthenticationGuard {

    protected loginUrl: string = '/login';

    constructor(protected authenticationService: IAuthenticationService, protected loggingService: ILoggingService, protected routingService: IRoutingService) {
        this.loggingService.debug('AuthenticationGuardBase --> constructor()');
    }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.doCanActivate(next, state);
    }

    protected doCanActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.loggingService.debug('AuthenticationGuardBase: doCanActivate()');
        let result: boolean = false;
        if (this.authenticationService.authenticationStatus === AuthenticationStatus.LoggedIn) {
            result = true;
        } else {
            this.routingService.routeTo(this.loginUrl, false);
            //this.routingService.routeToWithParams(this.loginUrl, { queryParams: { returnUrl: state.url } }, false);
        }

        return result;
    }

}
