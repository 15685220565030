import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {UtilsMisc} from './utils.misc';

export class UtilsRxjs {

    public static completeSubscription(subscription: Subject<any>): void {
        if (UtilsMisc.isNullOrUndefined(subscription) === false) {
            subscription.next();
            subscription.complete();
        }
    }

    public static createEmptySubscription(): Subject<void> {
        return (new Subject<void>());
    }

    public static createSubscription(timeToDebounce: number, callback: (value: any) => any): Subject<any> {
        const result: Subject<any> = new Subject<any>();
        result
            .pipe(debounceTime(timeToDebounce))
            .pipe(distinctUntilChanged())
            .subscribe(value => {
                callback(value);
            });

        return result;
    }

}
