import {UtilsMisc} from '../../utils/utils.misc';
import {ConfigAdmin, IConfigAdmin} from './config.admin';
import {ConfigData, IConfigData} from './config.data';
import {ConfigLogging, IConfigLogging} from './config.logging';
import {ConfigSystem, IConfigSystem} from './config.system';

export interface IConfigCore {

    configAdmin: IConfigAdmin;
    configData: IConfigData;
    configLogging: IConfigLogging;
    configSystem: IConfigSystem;

}

// @dynamic
export class ConfigCore {

    public static get configAdmin(): IConfigAdmin {
        return ConfigAdmin;
    }

    public static get configData(): IConfigData {
        return ConfigData;
    }

    public static get configLogging(): IConfigLogging {
        return ConfigLogging;
    }

    public static get configSystem(): IConfigSystem {
        return ConfigSystem;
    }

    public static assign(source: IConfigCore): void {
        if (UtilsMisc.isNullOrUndefinedOrEmpty(source) === false) {
            ConfigAdmin.assign(source.configAdmin);
            ConfigData.assign(source.configData);
            ConfigLogging.assign(source.configLogging);
            ConfigSystem.assign(source.configSystem);
        }
    }

}
