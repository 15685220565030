import {Injectable} from '@angular/core';
import {ConfigService} from '../../configuration/services/config.service';
import {LoggingService} from '../../logging/logging.service';
import {BaseService} from '../../services/misc/base.service';
import {UtilsMisc} from '../../utils/utils.misc';
import {UtilsString} from '../../utils/utils.string';
import {IErrorService} from '../interfaces/error.service.interface';

@Injectable({
    providedIn: 'root'
})
export class ErrorService extends BaseService implements IErrorService {

    constructor(configurationService: ConfigService, loggingService: LoggingService) {
        super(configurationService, loggingService);

        this.loggingService.debug('ErrorService --> constructor()');
    }

    public parseError(error: any): string[] {
        return this.doParseError(error);
    }

    public async showErrorAsync(errorMessages: string[]): Promise<void> {
        return (await this.doShowErrorAsync(errorMessages));
    }

    protected doParseError(error: any): string[] {
        let result: string[] = [];

        let errorMessage: string = null;
        if (UtilsMisc.isNullOrUndefined(error.error) === false) {
            if (UtilsString.stringIsNullOrEmpty(error.error.errorMessage) === false) {
                errorMessage = error.error.errorMessage;
            } else if (UtilsString.stringIsNullOrEmpty(error.error.exception) === false) {
                errorMessage = error.error.exception;
            }
        } else if (UtilsMisc.isNullOrUndefined(error.statusText) === false) {
            errorMessage = error.statusText;
        }
        if (UtilsString.stringIsNullOrEmpty(errorMessage) === false) {
            const errorMessageArray: string[] = errorMessage.split(';');
            if ((UtilsMisc.isNullOrUndefined(errorMessageArray) === false) && (errorMessageArray.length > 0)) {
                errorMessageArray.forEach(item => {
                    result.push(item);
                });
            }
        }


        return result;
    }

    protected async doShowErrorAsync(errorMessages: string[]): Promise<void> {
    }

}
