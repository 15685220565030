import {Injectable} from '@angular/core';
import {Config, LoadingController} from '@ionic/angular';
import {BehaviorSubject, Subscription} from 'rxjs';

import {ConfigService, IConfigService} from '../../configuration/services/config.service';
import {ILoggingService, LoggingService} from '../../logging/logging.service';
import {BaseService} from '../../services/misc/base.service';
import {IRoutingService, RoutingService} from '../../services/misc/routing.service';
import {UtilsMisc} from '../../utils/utils.misc';
import {UtilsString} from '../../utils/utils.string';
import {IApplicationService} from '../interfaces/application.service.interface';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService extends BaseService implements IApplicationService {

    protected isBusyCount: number = 0;

    constructor(protected routingService: RoutingService, protected loadingController: LoadingController, protected ionConfig: Config,
                configurationService: ConfigService, loggingService: LoggingService) {
        super(configurationService, loggingService);

        this.loggingService.debug('ApplicationService --> constructor()');
        this.loggingService.debug('platformMode: ' + this.platformMode);
    }

    public get isBusy(): boolean {
        return this.isBusy$.getValue();
    }

    public set isBusy(value: boolean) {
        if (value === true) {
            this.isBusyCount = this.isBusyCount + 1;
        } else if (this.isBusyCount > 0) {
            this.isBusyCount = this.isBusyCount - 1;
        }
        if (this.isBusyCount > 0) {
            this.isBusy$.next(true);
        } else {
            this.isBusy$.next(false);
        }
        /*if (value !== this.isBusy) {
            this.setIsBusyAsync(value);
        }*/
    }

    public get platformMode(): string {
        return this.ionConfig.get('mode');
    }

    protected _isBusy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    protected get isBusy$(): BehaviorSubject<boolean> {
        return this._isBusy$;
    }

    protected _loadingService: HTMLIonLoadingElement = null;

    protected get loadingService(): HTMLIonLoadingElement {
        return this._loadingService;
    }

    public getConfigurationService(): IConfigService {
        return this.configurationService;
    }

    public getLoggingService(): ILoggingService {
        return this.loggingService;
    }

    public getRoutingService(): IRoutingService {
        return this.routingService;
    }

    public async initializeAsync(): Promise<void> {
        await this.doInitializeAsync();
    }

    public async navigateToAsync(path: string, params: any = null): Promise<void> {
        await this.routingService.routeToAsync(path);
    }

    public parseError(error: any): string {
        return this.doParseError(error);
    }

    public async setIsBusyAsync(value: boolean, text: string = null): Promise<void> {
        if (value === true) {
            this._loadingService = await this.loadingController.create({
                showBackdrop: true
            });
            this.loadingService.message = text;
            await this.loadingService.present();
            this.isBusy$.next(true);
            await this.waitAsync(100);
        } else {
            if (this._loadingService !== null) {
                await this.loadingService.dismiss();
                this._loadingService = null;
                this.isBusy$.next(false);
                await this.waitAsync(100);
            }
        }
    }

    public async showErrorAsync(errorMessages: string[]): Promise<void> {
        return (await this.doShowErrorAsync(errorMessages));
    }

    public subscribeForBusyChange(method: (value: boolean) => void): Subscription {
        return this.isBusy$.subscribe(value => method(value));
    }

    public async waitAsync(value: number): Promise<void> {
        await UtilsMisc.waitAsync(value);
    }

    protected async doInitializeAsync(): Promise<void> {
    }

    protected doParseError(error: any): string {
        let result: string = null;

        if (UtilsMisc.isNullOrUndefined(error.error) === false) {
            if (UtilsString.stringIsNullOrEmpty(error.error.errorMessage) === false) {
                result = error.error.errorMessage;
            } else if (UtilsString.stringIsNullOrEmpty(error.error.exception) === false) {
                result = error.error.exception;
            }
        } else if (UtilsMisc.isNullOrUndefined(error.statusText) === false) {
            result = error.statusText;
        }

        return result;
    }

    protected async doShowErrorAsync(errorMessages: string[]): Promise<void> {
    }

}
