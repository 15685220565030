import {ModuleWithProviders, NgModule} from '@angular/core';
import {LoggingService} from 'src/core/pin.core';

@NgModule({
    imports: [],
    providers: []
})
export class PITTrackWebModelModule {

    constructor(loggingService: LoggingService) {
        loggingService.debug('PITTrackWebModelModule --> constructor()');

    }

    public static forRoot(): ModuleWithProviders<PITTrackWebModelModule> {
        return {
            ngModule: PITTrackWebModelModule,
            providers: []
        };
    }

}

