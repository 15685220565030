import {UtilsString} from './utils.string';

export class UtilsDate {

    public static dateIsEqual(value1: Date, value2: Date): boolean {
        let result: boolean = false;
        if ((value1 != null) && (value2 != null)) {
            result = ((new Date(value1.getFullYear(), value1.getMonth(), value1.getDate()).getTime()) === (new Date(value2.getFullYear(), value2.getMonth(), value2.getDate()).getTime()));
        }

        return result;
    }

    public static dateIsGreater(value1: Date, value2: Date): boolean {
        let result: boolean = false;
        if ((value1 != null) && (value2 != null)) {
            result = ((new Date(value1.getFullYear(), value1.getMonth(), value1.getDate()).getTime()) > (new Date(value2.getFullYear(), value2.getMonth(), value2.getDate()).getTime()));
        }

        return result;
    }

    public static dateIsSmaller(value1: Date, value2: Date): boolean {
        let result: boolean = false;
        if ((value1 != null) && (value2 != null)) {
            result = ((new Date(value1.getFullYear(), value1.getMonth(), value1.getDate()).getTime()) < (new Date(value2.getFullYear(), value2.getMonth(), value2.getDate()).getTime()));
        }

        return result;
    }

    public static dateTimeIsEqual(value1: Date, value2: Date): boolean {
        let result: boolean = false;
        if ((value1 != null) && (value2 != null)) {
            result = (value1.getTime() === value2.getTime());
        }

        return result;
    }

    public static dateTimeIsGreater(value1: Date, value2: Date): boolean {
        let result: boolean = false;
        if ((value1 != null) && (value2 != null)) {
            result = (value1.getTime() > value2.getTime());
        }

        return result;
    }

    public static dateTimeIsSmaller(value1: Date, value2: Date): boolean {
        let result: boolean = false;
        if ((value1 != null) && (value2 != null)) {
            result = (value1.getTime() < value2.getTime());
        }

        return result;
    }

    public static timeDiffInMinutes(value1: Date, value2: Date): number {
        let result: number = 0;
        if ((value1 != null) && (value2 != null)) {
            result = Math.trunc((value1.getTime() - value2.getTime()) / 1000 / 60);
        }

        return result;
    }

    public static timeIsEqual(value1: Date, value2: Date): boolean {
        let result: boolean = false;
        if ((value1 != null) && (value2 != null)) {
            result = (((value1.getHours() * 60) + value1.getMinutes()) === ((value2.getHours() * 60) + value2.getMinutes()));
        }

        return result;
    }

    public static timeIsGreater(value1: Date, value2: Date): boolean {
        let result: boolean = false;
        if ((value1 != null) && (value2 != null)) {
            //result = (((value1.getHours() * 60) + value1.getMinutes()) > ((value2.getHours() * 60) + value2.getMinutes()));
            result = (value1.getTime() > value2.getTime());
        }

        return result;
    }

    public static timeIsSmaller(value1: Date, value2: Date): boolean {
        let result: boolean = false;
        if ((value1 != null) && (value2 != null)) {
            //result = (((value1.getHours() * 60) + value1.getMinutes()) < ((value2.getHours() * 60) + value2.getMinutes()));
            result = (value1.getTime() < value2.getTime());
        }

        return result;
    }

    public static dateParseFromString(value: any): Date {
        let result: Date = null;
        if (value instanceof Date) {
            result = (value as Date);
        } else {
            if (!UtilsString.stringIsNullOrEmpty(value)) {
                value = value.replace(/-/g, '').replace(/T/g, '').replace(/:/g, '').replace(' ', '');
                if (value.length >= 12) {
                    result = new Date(
                        parseInt(value.substr(0, 4), 10),
                        parseInt(value.substr(4, 2), 10) - 1,
                        parseInt(value.substr(6, 2), 10),
                        parseInt(value.substr(8, 2), 10),
                        parseInt(value.substr(10, 2), 10)
                    );
                    /*result = new Date();
                    result.setDate(1);
                    result.setFullYear(parseInt(value.substr(0, 4), 10));
                    result.setMonth(parseInt(value.substr(4, 2), 10) - 1);
                    result.setDate(parseInt(value.substr(6, 2), 10));
                    result.setHours(parseInt(value.substr(8, 2), 10));
                    result.setMinutes(parseInt(value.substr(10, 2), 10));*/
                    if (value.length >= 14) {
                        result.setSeconds(parseInt(value.substr(12, 2), 10));
                    }
                }
            }
        }

        return result;
    }

    public static dateTimeToString(value: Date): string {
        let result = null;
        if (value != null) {
            result = UtilsString.stringFormat('{0}-{1}-{2} {3}:{4}:{5}',
                value.getFullYear().toString(),
                UtilsString.stringSetLeadingChars((value.getMonth() + 1).toString(), '0', 2),
                UtilsString.stringSetLeadingChars(value.getDate().toString(), '0', 2),
                UtilsString.stringSetLeadingChars(value.getHours().toString(), '0', 2),
                UtilsString.stringSetLeadingChars(value.getMinutes().toString(), '0', 2),
                UtilsString.stringSetLeadingChars(value.getSeconds().toString(), '0', 2));
        }

        return result;
    }

    public static dateTimeToLocaleString(value: Date): string {
        let result = null;
        if (value != null) {
            result = UtilsString.stringFormat('{0}.{1}.{2} {3}:{4}',
                UtilsString.stringSetLeadingChars(value.getDate().toString(), '0', 2),
                UtilsString.stringSetLeadingChars((value.getMonth() + 1).toString(), '0', 2),
                value.getFullYear().toString(),
                UtilsString.stringSetLeadingChars(value.getHours().toString(), '0', 2),
                UtilsString.stringSetLeadingChars(value.getMinutes().toString(), '0', 2));
        }

        return result;
    }

}
