import {UtilsMisc} from '../../utils/utils.misc';

export enum LogLevel {

    Debug = 0,
    Info = 1,
    Warn = 2,
    Error = 3,
    None = 4

}

export interface IConfigLogging {

    logLevel: LogLevel;

}

// @dynamic
export class ConfigLogging {

    private static _logLevel: LogLevel = LogLevel.Debug;

    public static get logLevel(): LogLevel {
        return ConfigLogging._logLevel;
    }

    public static assign(source: IConfigLogging): void {
        if (UtilsMisc.isNullOrUndefinedOrEmpty(source) === false) {
            ConfigLogging._logLevel = source.logLevel;
        }
    }

}
