import {UtilsMisc} from './utils.misc';

export interface IUtilsList<T extends any> {

    count: number;
    items: T[];

    add(item: T): void;

    addArray(items: T[]): void;

    addRange(items: IUtilsList<T>): void;

    clear(): void;

    contains(item: T): boolean;

    delete(item: T): void;

    deleteAt(index: number): void;

    elementAt(index: number): T;

    filter(callbackFunc: (value: T, index: number, array: T[]) => boolean, thisArg?: any): Array<T>;

    find(predicateFunc: (value: T, index: number, obj: Array<T>) => boolean, thisArg?: any): T;

    forEach(callbackFunc: (value: T, index: number, array: T[]) => void, thisArg?: any): void;

    indexOf(item: T): number;

    insertAt(index: number, item: T): void;

    moveToIndex(index: number, item: T): void;

    replace(index: number, item: T): void;

    sort(compareFunc: (a: T, b: T) => number): void;

    swapItems(item1: T, item2: T);

    swapItemsByIndex(index1: number, index2: number);

    toArray(): T[];

}

export class UtilsList<T extends any> implements IUtilsList<T> {

    constructor(items?: T[]) {
        this._items = ((UtilsMisc.isNullOrUndefined(items) === true) ? [] : items);
    }

    protected _items: T[] = [];

    public get items(): Array<T> {
        if (UtilsMisc.isNullOrUndefined(this._items) === true) {
            this._items = [];
        }

        return this._items;
    }

    public get count(): number {
        return this._items.length;
    }

    public add(item: T): void {
        this.doAdd(item);
    }

    public addArray(items: T[]): void {
        this.doAddArray(items);
    }

    public addRange(items: IUtilsList<T>): void {
        this.doAddRange(items);
    }

    public clear(): void {
        this.doClear();
    }

    public contains(item: T): boolean {
        return (this._items.some(x => (x === item)));
    }

    public delete(item: T): void {
        this.deleteAt(this.indexOf(item));
    }

    public deleteAt(index: number): void {
        this.doDeleteAt(index);
    }

    public elementAt(index: number): T {
        let result: T = null;
        try {
            result = this._items[index];
        } catch (exception) {
        }

        return result;
    }

    public filter(callbackFunc: (value: T, index: number, array: T[]) => boolean, thisArg?: any): Array<T> {
        return this._items.filter(callbackFunc, thisArg);
    }

    public find(predicateFunc: (value: T, index: number, obj: Array<T>) => boolean, thisArg?: any): T {
        const result: Array<T> = this.filter(predicateFunc, thisArg);

        return (((result === null) || (result.length === 0)) ? null : result[0]);
    }

    public forEach(callbackFunc: (value: T, index: number, array: T[]) => void, thisArg?: any): void {
        this._items.forEach(callbackFunc, thisArg);
    }

    public indexOf(item: T): number {
        return this._items.indexOf(item, 0);
    }

    public insertAt(index: number, item: T): void {
        this.doInsertAt(index, item);
    }

    public moveToIndex(index: number, item: T): void {
        const itemIndex: number = this.indexOf(item);
        if ((itemIndex >= 0) && (index >= 0) && (index < this.count) && (index !== itemIndex)) {
            this._items.splice(index, 0, this._items.splice(itemIndex, 1)[0]);
        }
    }

    public replace(index: number, item: T): void {
        if ((index >= 0) && (index < this.count)) {
            this._items[index] = item;
        } else if (item != null) {
            this.add(item);
        }
    }

    public sort(compareFunc: (a: T, b: T) => number): void {
        if (compareFunc != null) {
            this._items.sort(compareFunc);
        }
    }

    public swapItems(item1: T, item2: T): void {
        if ((item1 != null) && (item2 != null) && (this.contains(item1)) && (this.contains(item2))) {
            this.swapItemsByIndex(this.indexOf(item1), this.indexOf(item2));
        }
    }

    public swapItemsByIndex(index1: number, index2: number): void {
        this.doSwapItemsByIndex(index1, index2);
    }

    public toArray(): T[] {
        return this._items.slice();
    }

    protected doAdd(item: T): void {
        this._items.push(item);
    }

    protected doAddArray(items: T[]): void {
        if ((items != null) && (items.length > 0)) {
            items.forEach(x => {
                this._items.push(x);
            });
        }
    }

    protected doAddRange(items: IUtilsList<T>): void {
        if ((items != null) && (items.count > 0)) {
            items.forEach(x => {
                this._items.push(x);
            });
        }
    }

    protected doClear(): void {
        this._items.length = 0;
    }

    protected doDeleteAt(index: number): T {
        let result: T = null;
        try {
            result = this.elementAt(index);
            this._items.splice(index, 1);
        } catch (exception) {
        }

        return result;
    }

    protected doInsertAt(index: number, item: T): void {
        if (index >= 0) {
            if (index < this.count) {
                this._items.splice(index, 0, item);
            } else {
                this.add(item);
            }
        }
    }

    protected doSwapItemsByIndex(index1: number, index2: number): void {
        if ((index1 >= 0) && (index2 >= 0) && (index1 < this.count) && (index2 < this.count)) {
            const tmpItem: T = this.elementAt(index1);
            this._items[index1] = this._items[index2];
            this._items[index2] = tmpItem;
        }
    }

    protected doGetJSON(): string {
        return JSON.stringify(this._items);
    }

}
