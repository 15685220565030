import {IConfigService} from '../../configuration/services/config.service';
import {ILoggingService} from '../../logging/logging.service';
import {UtilsMisc} from '../../utils/utils.misc';

export interface IBaseService {

    serviceId: string;

}

export class BaseService implements IBaseService {

    constructor(protected configurationService: IConfigService, protected loggingService: ILoggingService) {
        this._serviceId = UtilsMisc.createGuid();

        this.loggingService.debug('BaseService --> constructor()');
        this.loggingService.debug('Service-ID: ' + this.serviceId);
    }

    private _serviceId: string = null;

    public get serviceId(): string {
        return this._serviceId;
    }

}

