import {Injectable} from '@angular/core';
import {ConfigAdmin, IConfigAdmin} from '../interfaces/config.admin';
import {ConfigCore, IConfigCore} from '../interfaces/config.core';
import {ConfigData, IConfigData} from '../interfaces/config.data';
import {ConfigLogging, IConfigLogging, LogLevel} from '../interfaces/config.logging';
import {ConfigSystem, IConfigSystem} from '../interfaces/config.system';

export interface IConfigService {

    configAdmin: IConfigAdmin;
    configCore: IConfigCore;
    configData: IConfigData;
    configLogging: IConfigLogging;
    configSystem: IConfigSystem;

}

@Injectable({
    providedIn: 'root'
})
export class ConfigService implements IConfigService {

    constructor() {
        if (ConfigCore.configLogging.logLevel === LogLevel.Debug) {
            console.debug('ConfigurationService --> constructor');
        }
    }

    public get configAdmin(): IConfigAdmin {
        return ConfigAdmin;
    }

    public get configCore(): IConfigCore {
        return ConfigCore;
    }

    public set configCore(value: IConfigCore) {
        ConfigCore.assign(value);
    }

    public get configData(): IConfigData {
        return ConfigData;
    }

    public set configData(value: IConfigData) {
        ConfigData.assign(value);
    }

    public get configLogging(): IConfigLogging {
        return ConfigLogging;
    }

    public set configLogging(value: IConfigLogging) {
        ConfigLogging.assign(value);
    }

    public get configSystem(): IConfigSystem {
        return ConfigSystem;
    }

    public set configSystem(value: IConfigSystem) {
        ConfigSystem.assign(value);
    }

}
