import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import {defineCustomElements} from '@ionic/pwa-elements/loader';
import {ConfigCore} from './core/pin.core';

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

if (environment.production) {
    enableProdMode();
}

if (window.location.pathname !== '/') {
    window.location.href = window.location.origin;
} else {

    fetch('/assets/config/config.json').then(response => response.json()).then(data => {
        ConfigCore.assign(data);
    }).then(() => {
        platformBrowserDynamic().bootstrapModule(AppModule)
            .catch(err => console.log(err));
    });
    /*platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.log(err));*/
}
