import {EMPTY, Observable} from 'rxjs';
import {ILoggingService, LoggingService} from '../../logging/logging.service';
import {EntityType, ICrudAsyncService, ICrudService} from './crud.service.interfaces';

export class CrudServiceBase<T extends EntityType> implements ICrudService<T> {

    constructor(logger: LoggingService) {
        this.logger.debug('CrudServiceBase --> constructor()');
    }

    private _logger: ILoggingService = null;

    protected get logger(): ILoggingService {
        return this._logger;
    }

    public delete(entity: T): Observable<any> {
        return this.doDelete(entity);
    }

    public deleteById(id: any): Observable<any> {
        return this.doDeleteById(id);
    }

    public getById(id: any): Observable<T> {
        return this.doGetById(id);
    }

    public loadAll(): Observable<T> {
        return this.doLoadAll();
    }

    public save(entity: T): Observable<T> {
        return this.doSave(entity);
    }

    protected doDelete(entity: T): Observable<any> {
        return EMPTY;
    }

    protected doDeleteById(id: any): Observable<any> {
        return EMPTY;
    }

    protected doGetById(id: any): Observable<T> {
        return EMPTY;
    }

    protected doLoadAll(): Observable<T> {
        return EMPTY;
    }

    protected doSave(entity: T): Observable<T> {
        return EMPTY;
    }

}

export class CrudAsyncServiceBase<T extends EntityType> extends CrudServiceBase<T> implements ICrudAsyncService<T> {

    constructor(logger: LoggingService) {
        super(logger);

        this.logger.debug('CrudAsyncServiceBase --> constructor()');
    }

    public async deleteAsync(entity: T): Promise<void> {
        return this.delete(entity).toPromise();
    }

    public async deleteByIdAsync(id: any): Promise<void> {
        return this.deleteById(id).toPromise();
    }

    public async getByIdAsync(id: any): Promise<T> {
        return this.getById(id).toPromise();
    }

    public async loadAllAsync(): Promise<T> {
        return this.loadAll().toPromise();
    }

    public async saveAsync(entity: T): Promise<T> {
        return this.save(entity).toPromise();
    }

}
