import {UtilsList} from './utils.list';
import {UtilsMisc} from './utils.misc';

// @dynamic
export class UtilsString {

    private static _regExpMail: RegExp = null;

    public static get regExpMail(): RegExp {
        if (this._regExpMail === null) {
            this._regExpMail = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        }

        return this._regExpMail;
    }

    public static stringCompare(source: string, dest: string): boolean {
        let result: boolean = false;
        if (UtilsString.stringIsNullOrEmpty(source) === UtilsString.stringIsNullOrEmpty(dest)) {
            if (UtilsString.stringIsNullOrEmpty(source) === false) {
                result = (source === dest);
            }
        }

        return result;
    }

    public static stringConcat(val1: string, val2: string): string {
        let result: string = null;
        if (!UtilsString.stringIsNullOrEmpty(val1)) {
            result = val1;
            if (!UtilsString.stringIsNullOrEmpty(val2)) {
                result = result + val2;
            }

        } else if (!UtilsString.stringIsNullOrEmpty(val2)) {
            result = val2;
        }

        return result;
    }

    public static stringContains(source: string, caseSensitive: boolean, values: string[]): boolean {
        let result: boolean = false;
        if ((!UtilsString.stringIsNullOrEmpty(source)) && (values != null) && (values.length > 0)) {
            for (let loop = 0; ((loop < values.length) && (!result)); loop++) {
                const value: string = values[loop];
                if (!UtilsString.stringIsNullOrEmpty(value)) {
                    if (caseSensitive) {
                        result = (source.indexOf(value) >= 0);
                        //result = source.includes(value);
                    } else {
                        result = (source.toLowerCase().indexOf(value.toLowerCase()) >= 0);
                        //result = source.toLowerCase().includes(value.toLowerCase());
                    }
                }
            }
        }

        return result;
    }

    public static stringEscape(value: string): string {
        let result: string = value;
        result = result.replace(/[\0\n\r\b\t\\''\x1a]/g, function(s) {
                switch (s) {
                    case '\0':
                        return '\\0';
                    case '\n':
                        return '\\n';
                    case '\r':
                        return '\\r';
                    case '\b':
                        return '\\b';
                    case '\t':
                        return '\\t';
                    case '\x1a':
                        return '\\Z';
                    case '\'':
                        return '\'\'';
                    case '"':
                        return '""';
                    default:
                        return '\\' + s;
                }
            }
        );

        return result;
    }

    public static stringFormat(source: string, ...params: any[]): string {
        let result: string = source;
        if (params != null) {
            const items: UtilsList<any> = new UtilsList<any>();
            for (let loop = 0; loop < params.length; loop++) {
                const item: any = params[loop];
                if (Array.isArray(item)) {
                    items.addArray(item as Array<any>);
                } else {
                    items.add(item);
                }
            }
            for (let loop = 0; loop < items.count; loop++) {
                const regexp: RegExp = new RegExp('\\{' + loop + '\\}', 'gi');
                result = result.replace(regexp, ((items.elementAt(loop) === null) ? '' : items.elementAt(loop).toString()));
            }
        }

        return result;
    }

    public static stringIsNullOrEmpty(value: string): boolean {
        return ((UtilsMisc.isNullOrUndefinedOrEmpty(value) === true) || (value === ''));
    }

    public static stringIsValidMail(value: string, canBeNull: boolean = false): boolean {
        return ((UtilsString.stringIsNullOrEmpty(value) && canBeNull) || UtilsString.regExpMail.test(value));
    }

    public static stringSetFinalChars(value: string, finalChar: string, maxlength: number): string {
        let result: string = (UtilsString.stringIsNullOrEmpty(value) ? '' : value);
        while (result.length < maxlength) {
            result = UtilsString.stringFormat('{0}{1}', result, finalChar);
        }

        return result;
    }

    public static stringSetLeadingChars(value: string, leadingChar: string, maxlength: number): string {
        let result: string = (UtilsString.stringIsNullOrEmpty(value) ? '' : value);
        while (result.length < maxlength) {
            result = UtilsString.stringFormat('{0}{1}', leadingChar, result);
        }

        return result;
    }

    public static stringShiftLeft(value: string, count: number = 1): string {
        let result: string = value;
        if ((!UtilsString.stringIsNullOrEmpty(value)) && (value.length > 1)) {
            if (count === 0) {
                count = 1;
            }
            for (let loop = 0; loop < count; loop++) {
                result = result.substr(1, result.length - 1) + result.substr(0, 1);
            }
        }

        return result;
    }

    public static stringShiftRight(value: string, count: number = 1): string {
        let result: string = value;
        if ((!UtilsString.stringIsNullOrEmpty(value)) && (value.length > 1)) {
            if (count === 0) {
                count = 1;
            }
            for (let loop = 0; loop < count; loop++) {
                result = result.substr(result.length - 1, 1) + result.substr(0, result.length - 1);
            }
        }

        return result;
    }

    public static stringValueOrDefault(value: string, defaultValue: string): string {
        return ((UtilsString.stringIsNullOrEmpty(value) === true) ? defaultValue : value);
    }

}
