import {ModuleWithProviders, NgModule} from '@angular/core';
import {LoggingService} from 'src/core/pin.core';
import {PITTrackWebModelModule} from '../../model/modules/pit.track.web.model.module';

@NgModule({
    imports: [
        PITTrackWebModelModule
    ]
})
export class PITTrackWebDataModule {

    constructor(loggingService: LoggingService) {
        loggingService.debug('PITTrackWebDataModule --> constructor()');

    }

    public static forRoot(): ModuleWithProviders<PITTrackWebDataModule> {
        return {
            ngModule: PITTrackWebDataModule,
            providers: []
        };
    }

}
