import {IApplicationService} from '../application/interfaces/application.service.interface';
import {IDesktopService} from '../application/services/desktop.service';
import {BaseComponent} from './component.base';

export class AppComponentBase extends BaseComponent {

    constructor(public desktopService: IDesktopService, applicationService: IApplicationService) {
        super(applicationService);

        this.initializeAppAsync();
    }

    public initializeApp(): void {
        this.doInitializeApp();
    }

    public async initializeAppAsync(): Promise<void> {
        await this.doInitializeAppAsync();
    }

    protected doInitializeApp(): void {
    }

    protected async doInitializeAppAsync(): Promise<void> {
    }

}
