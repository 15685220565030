import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {LoggingService} from '../../logging/logging.service';

export interface IRoutingService {

    activatedRoute: ActivatedRoute;
    router: Router;

    routeTo(path: string, skipLocationChange?: boolean): void;

    routeToAsync(path: string, skipLocationChange?: boolean): Promise<void>;

    routeToWithParams(path: string, queryParams: any, skipLocationChange?: boolean): void;

    routeToWithParamsAsync(path: string, queryParams: any, skipLocationChange?: boolean): Promise<void>;

    subscribeForNavigationStart(method: (value: string) => void): Subscription;

    subscribeForNavigationEnd(method: (value: string) => void): Subscription;

}

@Injectable({
    providedIn: 'root'
})
export class RoutingService implements IRoutingService {

    protected routeChanged$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    protected navigationEnd$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    protected navigationStart$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(public router: Router, public activatedRoute: ActivatedRoute, protected loggingService: LoggingService) {
        this.loggingService.debug('RoutingService --> constructor()');
    }

    public routeTo(path: string, skipLocationChange: boolean = true): void {
        this.router.navigate([path], {
            skipLocationChange: skipLocationChange
        });
        this.routeChanged$.next(path);
    }

    public routeToWithParams(path: string, queryParams: any, skipLocationChange: boolean = true): void {
        this.router.navigate([path], {
            queryParams: queryParams,
            skipLocationChange: skipLocationChange
        });
        this.routeChanged$.next(path);
    }

    public async routeToAsync(path: string, skipLocationChange: boolean = true): Promise<void> {
        this.router.navigate([path], {
            skipLocationChange: skipLocationChange
        });
        this.routeChanged$.next(path);
    }

    public async routeToWithParamsAsync(path: string, queryParams: any, skipLocationChange: boolean = true): Promise<void> {
        this.router.navigate([path], {
            queryParams: queryParams,
            skipLocationChange: skipLocationChange
        });
        this.routeChanged$.next(path);
    }

    public subscribeForNavigationEnd(method: (value: string) => void): Subscription {
        return this.router.events
            .pipe(
                filter(event => (event instanceof NavigationEnd))
            )
            .subscribe(event => {
                method((event as NavigationEnd).url);
            });
    }

    public subscribeForNavigationStart(method: (value: string) => void): Subscription {
        return this.router.events
            .pipe(
                filter(event => (event instanceof NavigationStart))
            )
            .subscribe(event => {
                method((event as NavigationStart).url);
            });
    }

}
