import {Injectable} from '@angular/core';
import {LoggingService} from '../../logging/logging.service';
import {RoutingService} from '../../services/misc/routing.service';
import {AuthenticationService} from '../services/authentication.service';
import {AuthenticationGuardBase} from './authentication.guard.base';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard extends AuthenticationGuardBase {

    constructor(authenticationService: AuthenticationService, loggingService: LoggingService, routingService: RoutingService) {
        super(authenticationService, loggingService, routingService);

        this.loggingService.debug('AuthenticationGuard --> constructor()');
    }

}
