import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

import {ConfigService} from '../../configuration/services/config.service';
import {IUserProfileEntity} from '../../entities/authentication/authentication.interfaces';
import {LoggingService} from '../../logging/logging.service';
import {BaseService, IBaseService} from '../../services/misc/base.service';

export enum AuthenticationStatus {

    LoggedOut = 0,
    InLogin = 1,
    WaitingFor2WayAuthentication = 2,
    LoggedIn = 3,
    InLogout = 4,
    InRegister = 5

}

export enum LoginType {

    Biometric = 0,
    Credentials = 1,
    EasyEntry = 2

}

export interface IAppProfile {

    ip?: string;
    userProfile?: IUserProfileEntity;

}

export interface IAuthenticationService extends IBaseService {

    authenticationStatus: AuthenticationStatus;

    initializeAsync(): Promise<void>;

    loginAsync(loginType: LoginType, username: string, password: string, navigateToHome?: boolean): Promise<any>;

    logoutAsync(userId: any): Promise<any>;

    registerAsync(appProfile: IAppProfile): Promise<any>;

    resetPasswordAsync(userName: string): Promise<any>;

    subscribeForAuthenticationStatusChange(method: (status: AuthenticationStatus) => void): Subscription;

}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends BaseService implements IAuthenticationService {

    constructor(configurationService: ConfigService, loggingService: LoggingService) {
        super(configurationService, loggingService);

        this.loggingService.debug('AuthenticationService --> constructor()');
    }

    public get authenticationStatus(): AuthenticationStatus {
        return this.authenticationStatus$.getValue();
    }

    public set authenticationStatus(value: AuthenticationStatus) {
        this.doSetAuthenticationStatus(value);
    }

    private _authenticationStatus$: BehaviorSubject<AuthenticationStatus> = new BehaviorSubject<AuthenticationStatus>(AuthenticationStatus.LoggedOut);

    protected get authenticationStatus$(): BehaviorSubject<AuthenticationStatus> {
        return this._authenticationStatus$;
    }

    public async initializeAsync(): Promise<void> {
        await this.doInitializeAsync();
    }

    public async loginAsync(loginType: LoginType, userName: string, password: string, navigateToHome: boolean = true): Promise<any> {
        return (await this.doLoginAsync(loginType, userName, password, navigateToHome));
    }

    public async logoutAsync(userId: any): Promise<any> {
        return (await this.doLogoutAsync(userId));
    }

    public async sendVerificationCodeAsync(code: string): Promise<any> {
        return (await this.doSendVerificationCodeAsync(code));
    }

    public async registerAsync(appProfile: IAppProfile): Promise<any> {
        return (await this.doRegisterAsync(appProfile));
    }

    public async resetPasswordAsync(username: string): Promise<any> {
        return (await this.doResetPasswordAsync(username));
    }

    public subscribeForAuthenticationStatusChange(method: (status: AuthenticationStatus) => void): Subscription {
        return this.authenticationStatus$.subscribe(status => (method(status)));
    }

    protected async doInitializeAsync(): Promise<void> {
    }

    protected async doLoginAsync(loginType: LoginType, userName: string, password: string, navigateToHome: boolean = true): Promise<any> {
        return null;
    }

    protected async doLogoutAsync(userId: any): Promise<any> {
        return null;
    }

    protected async doRegisterAsync(appProfile: IAppProfile): Promise<any> {
        return null;
    }

    protected async doResetPasswordAsync(username: string): Promise<any> {
        return null;
    }

    protected async doSendVerificationCodeAsync(code: string): Promise<any> {
    }

    protected doSetAuthenticationStatus(value: AuthenticationStatus): void {
        if (value !== this.authenticationStatus) {
            this.authenticationStatus$.next(value);
        }
    }

}
