import {ApplicationService} from '../application/services/application.service';
import {UtilsMisc} from '../utils/utils.misc';
import {BaseComponent} from './component.base';

export class PageBaseComponent extends BaseComponent {

    constructor(applicationService: ApplicationService) {
        super(applicationService);
    }

    public async ionViewWillEnter(): Promise<void> {
        await this.doOnIonViewWillEnterAsync();
    }

    public async ionViewDidEnter(): Promise<void> {
        await this.doOnIonViewDidEnterAsync();
    }

    public async ionViewWillLeave(): Promise<void> {
        await this.doOnIonViewWillLeaveAsync();
    }

    public async ionViewDidLeave(): Promise<void> {
        await this.doOnIonViewDidLeaveAsync();
    }

    protected async doOnIonViewWillEnterAsync(): Promise<void> {
        if (UtilsMisc.isNullOrUndefined(this.activatedSubject) === false) {
            this.activatedSubject.next(true);
        }
    }

    protected async doOnIonViewDidEnterAsync(): Promise<void> {
    }

    protected async doOnIonViewWillLeaveAsync(): Promise<void> {
    }

    protected async doOnIonViewDidLeaveAsync(): Promise<void> {
    }

}
