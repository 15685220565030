import {UtilsJson} from '../../utils/utils.json';
import {IModelBase} from '../interfaces/model.base.interface';

export class ModelBase implements IModelBase {

    public tag: any = null;

    constructor(initValues: Partial<IModelBase> = null) {
        if (initValues !== null) {
            Object.assign(this, initValues);
        }
    }

    public assign(source: IModelBase): void {
        this.doAssign(source);
    }

    public clone(): IModelBase {
        let result: IModelBase = null;
        try {
            const classReference = this.constructor as new () => this;
            result = (UtilsJson.deserialize(this.toJSON(), classReference) as IModelBase);
            result.setReferences();
        } catch (error) {
        }

        return result;
    }

    public async initializeAsync(): Promise<void> {
        await this.doInitializeAsync();
    }

    public removeReferences(): void {
        this.doRemoveReferences();
    }

    public setReferences(): void {
        this.doSetReferences();
    }

    public toJSON(): any {
        return this.doToJSON();
    }

    protected doAssign(source: IModelBase): void {
    }

    protected async doInitializeAsync(): Promise<void> {
    }

    protected doRemoveReferences(): void {
    }

    protected doSetReferences(): void {
    }

    protected doToJSON(): any {
        try {
            this.removeReferences();
        } catch (error) {
            //console.log(error);
        }
        const result: any = UtilsJson.serialize(this);
        try {
            this.setReferences();
        } catch (error) {
            //console.log(error);
        }

        return result;
    }

}
