import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoggingService} from 'src/core/pin.core';

@NgModule({
    imports: [
        CommonModule
    ]
})
export class PinSignalRModule {

    constructor(logger: LoggingService) {
        logger.debug('PinSignalRModule --> constructor()');
    }

    public static forRoot(): ModuleWithProviders<PinSignalRModule> {
        return {
            ngModule: PinSignalRModule,
            providers: []
        };
    }

}
