import {JsonObject, JsonProperty} from 'json2typescript';
import {ModelEntity} from 'src/core/pin.core';
import {IModelMailingStateType, MailingStateTypeGroup} from '../interfaces/model.mailing.state.type.interface';

// @dynamic
@JsonObject('ModelMailingStateType')
export class ModelMailingStateType extends ModelEntity implements IModelMailingStateType {

    @JsonProperty('code', String, true)
    public code: string = null;
    @JsonProperty('description', String, true)
    public description: string = null;
    @JsonProperty('text', String, true)
    public text?: string = null;
    @JsonProperty('group', Number, true)
    public group?: MailingStateTypeGroup = MailingStateTypeGroup.InProgress;

    constructor(initValues: Partial<IModelMailingStateType> = null) {
        super();

        if (initValues !== null) {
            Object.assign(this, initValues);
        }
    }

}
