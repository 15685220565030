import {Injectable} from '@angular/core';
import {LogLevel} from '../configuration/interfaces/config.logging';
import {ConfigService} from '../configuration/services/config.service';
import {LoggingService} from './logging.service';

@Injectable({
    providedIn: 'root'
})
export class LoggingConsoleService extends LoggingService {

    constructor(configService: ConfigService) {
        super(configService);

        this.debug('LoggingConsoleService --> constructor()');
    }

    protected doWriteLog(value: any): void {
        console.log(value);
    }

    protected doWriteDebug(value: any): void {
        if (this.config.logLevel === LogLevel.Debug) {
            console.debug(value);
        }
    }

    protected doWriteInfo(value: any): void {
        if (this.config.logLevel <= LogLevel.Info) {
            console.info(value);
        }
    }

    protected doWriteWarn(value: any): void {
        if (this.config.logLevel <= LogLevel.Warn) {
            console.warn(value);
        }
    }

    protected doWriteError(value: any): void {
        if (this.config.logLevel <= LogLevel.Error) {
            console.error(value);
        }
    }

}
