import {LOCALE_ID, NgModule} from '@angular/core';
import {Location, registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {ngxLoadingAnimationTypes, NgxLoadingModule} from 'ngx-loading';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing.module';
import {PITTrackWebCoreModule} from './modules/core/modules/pit.track.web.core.module';
import {PITTrackWebModelModule} from './modules/model/modules/pit.track.web.model.module';
import {PITTrackWebDataModule} from './modules/data/modules/pit.track.web.data.module';
import {EntityDataServiceAccessorService} from 'src/core/pin.data/implementations/entity.data.service.accessor.service';
import {ModelMailingEntityDataService} from './modules/data/implementations/model.mailing.entity.data.service';
import {ApplicationService, UtilsString} from 'src/core/pin.core';
import {IModelMailing} from './modules/model/interfaces/model.mailing.interface';

registerLocaleData(localeDe);

@NgModule({
    declarations: [
        AppComponent
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,

        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.chasingDots,
            backdropBackgroundColour: '#00000080',
            primaryColour: '#dd0031',
            secondaryColour: '#006ddd'
        }),
        IonicModule.forRoot({
            mode: 'ios'
        }),

        PITTrackWebCoreModule.forRoot(),
        PITTrackWebModelModule.forRoot(),
        PITTrackWebDataModule.forRoot(),

        AppRoutingModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: LOCALE_ID, useValue: 'de-de'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(
        private location: Location,
        dataServiceAccessor: EntityDataServiceAccessorService,
        mailingService: ModelMailingEntityDataService,
        applicationService: ApplicationService) {
        if (window.location.pathname !== '/') {
            window.location.href = window.location.origin;
        } else {
            dataServiceAccessor.registerEntityDataService<IModelMailing>('mailing', mailingService);
            if (UtilsString.stringIsNullOrEmpty(window.location.search) === false) {
                const urlParams = new URLSearchParams(window.location.search);
                this.location.replaceState('');
                applicationService.getRoutingService().routeToWithParamsAsync('state', {
                    ref: urlParams.get('ref'),
                    zip: urlParams.get('zip')
                });
            } else {
                applicationService.navigateToAsync('state');
            }
        }
    }

}
