import {Injectable} from '@angular/core';
import {Config, LoadingController} from '@ionic/angular';
import {ApplicationService, ConfigService, ErrorService, LoggingService, RoutingService} from 'src/core/pin.core';
import {IPITTrackWebApplicationService} from '../interfaces/pit.track.web.application.service.interface';

@Injectable({
    providedIn: 'root'
})
export class PITTrackWebApplicationService extends ApplicationService implements IPITTrackWebApplicationService {

    constructor(routingService: RoutingService, loadingController: LoadingController, ionConfig: Config,
                configurationService: ConfigService, loggingService: LoggingService, private errorService: ErrorService) {
        super(routingService, loadingController, ionConfig, configurationService, loggingService);

        this.loggingService.debug('PITTrackWebApplicationService --> constructor()');
    }

    protected async doShowErrorAsync(errorMessages: string[]): Promise<void> {
        await this.errorService.showErrorAsync(errorMessages);
    }

}
