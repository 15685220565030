import {UtilsMisc} from './utils.misc';

export interface IUtilsDictionary<T extends any> {

    isEmpty: boolean;
    items: any[];
    keys: string[];
    values: T[];

    add(key: string, value: T): void;

    clear(): void;

    containsKey(key: string): boolean;

    getKey(value: T): string;

    getValue(key: string): T;

    remove(key: string): void;

    set(key: string, value: T): void;

}

export class UtilsDictionary<T extends any> implements IUtilsDictionary<T> {

    constructor(init?: { key: string; value: T; }[]) {
        if (init != null) {
            for (const item of init) {
                this[item.key] = item.value;
                this.keys.push(item.key);
                this.values.push(item.value);
            }
        }
    }

    protected _keys: string[] = null;

    public get keys(): string[] {
        if (this._keys === null) {
            this._keys = new Array<string>();
        }

        return this._keys;
    }

    protected _values: T[] = null;

    public get values(): T[] {
        if (this._values === null) {
            this._values = new Array<T>();
        }

        return this._values;
    }

    public get isEmpty(): boolean {
        return ((this._keys === null) || (this._keys.length === 0));
    }

    public get items(): any[] {
        const result: any[] = [];
        this.keys.forEach(key => {
            result.push({
                key: key,
                value: this[key]
            });
        });

        return result;
    }

    public add(key: string, value: T): void {
        if (!this.containsKey(key)) {
            this[key] = value;
            this.keys.push(key);
            this.values.push(value);
        } else {
            this.set(key, value);
        }
    }

    public clear(): void {
        this.keys.splice(0, this.keys.length);
        this.values.splice(0, this.values.length);
    }

    public containsKey(key: string): boolean {
        return (this.keys.find(x => (x === key)) != null);
    }

    public getKey(value: T): string {
        let result: string = null;
        if (UtilsMisc.isNullOrUndefined(value) === false) {
            const index: number = this.values.indexOf(value);
            if (index >= 0) {
                result = this.keys[index];
            }
        }

        return result;
    }

    public getValue(key: string): T {
        let result: T = null;
        if (this.containsKey(key)) {
            result = this[key];
        }

        return result;
    }

    public remove(key: string): void {
        if (this.containsKey(key)) {
            const index = this._keys.indexOf(key, 0);
            this.keys.splice(index, 1);
            this.values.splice(index, 1);
        }

        delete this[key];
    }

    public set(key: string, value: T): void {
        if (this.containsKey(key)) {
            this[key] = value;
            const index: number = this.keys.indexOf(key);
            this.values[index] = value;
        } else {
            this.add(key, value);
        }
    }

}
