import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'state',
        pathMatch: 'full'
    },
    {
        path: 'state',
        loadChildren: () => import('./modules/state/modules/pit.track.web.state.module').then(m => m.PITTrackWebStateModule)
    }
    /*{
        path: 'contact',
        loadChildren: () => import('./modules/contact/modules/pit.track.web.contact.module').then(m => m.PITTrackWebContactModule)
    }*/
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: false,
            preloadingStrategy: PreloadAllModules
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
