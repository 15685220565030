import {UtilsMisc} from './utils.misc';

// @dynamic
export class UtilsArray {

    public static addItem(source: any[], item: any): void {
        if (UtilsMisc.isNullOrUndefined(source) === false) {
            source.push(item);
        }
    }

    public static indexOf(source: any[], item: any): number {
        let result: number = -1;
        if ((UtilsMisc.isNullOrUndefined(source) === false) && (UtilsMisc.isNullOrUndefined(item) === false)) {
            result = source.indexOf(item);
        }

        return result;
    }

    public static removeItem(source: any[], item: any): void {
        if ((UtilsMisc.isNullOrUndefined(source) === false) && (UtilsMisc.isNullOrUndefined(item) === false)) {
            const index: number = UtilsArray.indexOf(source, item);
            if (index >= 0) {
                source.splice(index, 1);
            }
        }
    }

}
