import {ModelEntity} from '../implementations/model.entity';
import {ICredentialsEntity} from './authentication.interfaces';

export class CredentialsEntity extends ModelEntity implements ICredentialsEntity {

    constructor(initValues: Partial<ICredentialsEntity> = null) {
        super();

        if (initValues !== null) {
            Object.assign(this, initValues);
        }
    }

    protected _email: string = null;

    public get email(): string {
        return this._email;
    }

    public set email(value: string) {
        this._email = value;
    }

    protected _password: string = null;

    public get password(): string {
        return this._password;
    }

    public set password(value: string) {
        this._password = value;
    }

    protected _username: string = null;

    public get username(): string {
        return this._username;
    }

    public set username(value: string) {
        this._username = value;
    }

}
