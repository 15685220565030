import * as uuid from 'uuid';

// @dynamic
export class UtilsMisc {

    public static createGuid(): string {
        return uuid.v4();
    }

    public static async executeAsync<T>(method: (params?: any) => T): Promise<T> {
        return (new Promise((r, j) => method));
    }

    public static isEmpty(value: any): boolean {
        return ((UtilsMisc.isNullOrUndefined(value) === false) && (Object.keys(value).length === 0));
    }

    public static isNullOrUndefinedOrEmpty(value: any): boolean {
        return ((UtilsMisc.isNullOrUndefined(value) === true) || (Object.keys(value).length === 0));
    }

    public static isNullOrUndefined(value: any): boolean {
        return ((value === null) || (value === undefined) || (typeof value === 'undefined'));
    }

    public static lightenHexColor(sourceColor: string, percentage: number): string {
        const num: number = parseInt(sourceColor.replace('#', ''), 16);
        const amt: number = Math.round(2.55 * percentage);
        const R: number = (num >> 16) + amt;
        const B: number = (num >> 8 & 0x00FF) + amt;
        const G: number = (num & 0x0000FF) + amt;

        return '#' + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
    }

    public static parseObjectForProperty(source: any, sourceKey: string): any {
        let result: any = null;
        if (UtilsMisc.isNullOrUndefinedOrEmpty(source) === false) {
            if (Array.isArray(source) === true) {
                for (let loop = 0; ((loop < source.length) && (result === null)); loop++) {
                    result = UtilsMisc.parseObjectForProperty(source[loop], sourceKey);
                }
            } else if (typeof source !== 'string') {
                const keys: string[] = Object.keys(source);
                if (UtilsMisc.isNullOrUndefinedOrEmpty(keys) === false) {
                    if (keys.indexOf(sourceKey) >= 0) {
                        const value: any = source[sourceKey];
                        if ((typeof value === 'string') || (UtilsMisc.isEmpty(value) === true)) {
                            result = value;
                        } else {
                            result = UtilsMisc.parseObjectForProperty(value, sourceKey);
                        }
                    } else {
                        for (let loop = 0; ((loop < keys.length) && (result === null)); loop++) {
                            result = UtilsMisc.parseObjectForProperty(source[keys[loop]], sourceKey);
                        }
                    }
                }
            }
        }

        return result;
    }

    public static async waitAsync(ms: number): Promise<void> {
        return (new Promise((r, j) => setTimeout(r, ms)));
    }

}
