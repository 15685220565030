import {JsonObject, JsonProperty} from 'json2typescript';
import {UtilsArray} from '../../utils/utils.array';
import {UtilsJsonConverterDate} from '../../utils/utils.json.converter';
import {UtilsMisc} from '../../utils/utils.misc';
import {UtilsString} from '../../utils/utils.string';
import {IModelEntity} from '../interfaces/model.entity.interface';
import {ModelBase} from './model.base';

// @dynamic
@JsonObject('ModelEntity')
export class ModelEntity extends ModelBase implements IModelEntity {

    @JsonProperty('id', Number, true)
    public id: number | null = null;
    @JsonProperty('guid', String, true)
    public guid: string = UtilsMisc.createGuid();
    @JsonProperty('createdAt', UtilsJsonConverterDate, true)
    public createdAt: Date | null = null;
    @JsonProperty('createdBy', String, true)
    public createdBy: string = null;
    @JsonProperty('modifiedAt', UtilsJsonConverterDate, true)
    public modifiedAt: Date | null = null;
    @JsonProperty('modifiedBy', String, true)
    public modifiedBy: string = null;
    @JsonProperty('flags', [String], true)
    public flags?: string[] = [];
    public transactionId: string = null;

    constructor(initValues: Partial<IModelEntity> = null) {
        super();

        if (initValues !== null) {
            Object.assign(this, initValues);
        }

        if (UtilsString.stringIsNullOrEmpty(this.guid)) {
            this.guid = UtilsMisc.createGuid();
        }
    }

    public getHasFlag(flag: string): boolean {
        return (UtilsArray.indexOf(this.flags, flag) >= 0);
    }

    public removeFlag(flag: string): void {
        UtilsArray.removeItem(this.flags, flag);
    }

    public setFlag(flag: string): void {
        if ((UtilsString.stringIsNullOrEmpty(flag) === false) && (!this.getHasFlag(flag))) {
            this.flags.push(flag);
        }
    }

    protected doAssign(source: IModelEntity): void {
        super.doAssign(source);

        if (UtilsMisc.isNullOrUndefined(source) === false) {
            this.id = source.id;
            this.guid = source.guid;
            this.createdAt = source.createdAt;
            this.createdBy = source.createdBy;
            this.modifiedAt = source.modifiedAt;
            this.modifiedBy = source.modifiedBy;
            this.flags = source.flags;
        }
    }

}
