import {Injectable} from '@angular/core';
import {ConfigService} from '../../configuration/services/config.service';
import {LoggingService} from '../../logging/logging.service';
import {BaseService, IBaseService} from '../../services/misc/base.service';
import {RoutingService} from '../../services/misc/routing.service';
import {UtilsMisc} from '../../utils/utils.misc';

export interface IAppPage {

    icon?: string;
    title?: string;
    url?: string;
    method?: () => Promise<void>;

}

export interface IDesktopService extends IBaseService {

    appPages?: IAppPage[];
    applicationTitle?: string;
    selectedPage?: IAppPage;

    navigateToAsync(appPage: IAppPage): Promise<void>;

}

@Injectable({
    providedIn: 'root'
})
export class DesktopService extends BaseService implements IDesktopService {

    public applicationTitle: string = null;
    public selectedPage: IAppPage = null;

    constructor(configurationService: ConfigService, loggingService: LoggingService, protected routingService: RoutingService) {
        super(configurationService, loggingService);
    }

    protected _appPages: IAppPage[] = [];

    public get appPages(): IAppPage[] {
        return this._appPages;
    }

    public async navigateToAsync(appPage: IAppPage): Promise<void> {
        if (UtilsMisc.isNullOrUndefined(appPage.method) === false) {
            await appPage.method();
        } else {
            await this.routingService.routeToAsync(appPage.url);
        }
    }

}
