import {IModelEntity} from 'src/core/pin.core';

export enum MailingStateTypeGroup {

    InProgress = 0,
    InDelivery = 1,
    IsDelivered = 2,
    WaitForAction = 3,
    IsProblem = 4

}

export interface IModelMailingStateType extends IModelEntity {

    code?: string;
    description?: string;
    text?: string;
    group?: MailingStateTypeGroup;

}
