import {UtilsDate} from '../../utils/utils.date';
import {UtilsMisc} from '../../utils/utils.misc';
import {IModelBase} from './model.base.interface';

export interface IModelEntity extends IModelBase {

    id?: number | null;
    guid?: string;
    createdAt?: Date | null;
    createdBy?: string;
    modifiedAt?: Date | null;
    modifiedBy?: string;
    transactionId?: string;
    flags?: string[];

}

export const entityCopy = (source: IModelEntity, dest: IModelEntity) => {
    const result: IModelEntity = ((UtilsMisc.isNullOrUndefined(dest) === false) ? dest : {});
    result.id = source.id;
    result.guid = source.guid;
    result.createdAt = source.createdAt;
    result.createdBy = source.createdBy;
    result.modifiedAt = source.modifiedAt;
    result.modifiedBy = source.modifiedBy;

    return result;
};

export const entityInit = (entity: IModelEntity) => {
    entity.createdAt = UtilsDate.dateParseFromString((entity.createdAt as any));
    entity.modifiedAt = UtilsDate.dateParseFromString((entity.modifiedAt as any));

    return entity;
};
