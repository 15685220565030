import {Injectable} from '@angular/core';

import {IConfigLogging, LogLevel} from '../configuration/interfaces/config.logging';
import {ConfigService, IConfigService} from '../configuration/services/config.service';

export interface ILoggingService {

    log: (value: any) => void;
    debug: (value: any) => void;
    info: (value: any) => void;
    warn: (value: any) => void;
    error: (value: any) => void;

}

@Injectable({
    providedIn: 'root'
})
export class LoggingService implements ILoggingService {

    constructor(configService: ConfigService) {
        this._configService = configService;

        this.debug('LoggingService --> constructor()');
    }

    private _configService: IConfigService = null;

    protected get configService(): IConfigService {
        return this._configService;
    }

    protected get config(): IConfigLogging {
        return this.configService.configLogging;
    }

    public log(value: any): void {
        this.doWriteLog(value);
    }

    public debug(value: any): void {
        this.doWriteDebug(value);
    }

    public info(value: any): void {
        this.doWriteInfo(value);
    }

    public warn(value: any): void {
        this.doWriteWarn(value);
    }

    public error(value: any): void {
        this.doWriteError(value);
    }

    protected doWriteLog(value: any): void {
        console.log(value);
    }

    protected doWriteDebug(value: any): void {
        if (this.config.logLevel === LogLevel.Debug) {
            console.debug(value);
        }
    }

    protected doWriteInfo(value: any): void {
        if (this.config.logLevel <= LogLevel.Info) {
            console.info(value);
        }
    }

    protected doWriteWarn(value: any): void {
        if (this.config.logLevel <= LogLevel.Warn) {
            console.warn(value);
        }
    }

    protected doWriteError(value: any): void {
        if (this.config.logLevel <= LogLevel.Error) {
            console.error(value);
        }
    }

}
