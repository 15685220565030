import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {IUtilsDictionary, UtilsDictionary} from './utils.dictionary';

export class UtilsJson {

    private static _registryClasses: IUtilsDictionary<{ new(): any; }> = null;

    private static get registryClasses(): IUtilsDictionary<{ new(): any; }> {
        if (this._registryClasses === null) {
            this._registryClasses = new UtilsDictionary<{ new(): any; }>();
        }

        return this._registryClasses;
    }

    private static _registryTypes: IUtilsDictionary<any> = null;

    private static get registryTypes(): IUtilsDictionary<any> {
        if (this._registryTypes === null) {
            this._registryTypes = new UtilsDictionary<any>();
        }

        return this._registryTypes;
    }

    public static deserialize<T>(json: any, classReference: { new(): T; }): T | T[] {
        let result: T | T[] = null;
        if (json !== null) {
            try {
                const jsonConvert: JsonConvert = new JsonConvert();
                jsonConvert.ignorePrimitiveChecks = false;
                jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
                //jsonConvert.operationMode = OperationMode.LOGGING;
                result = jsonConvert.deserialize(json, classReference);
            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        return result;
    }

    public static deserializeExt(json: any, classReference: { new(): any; }): any | any[] {
        let result: any | any[] = null;
        if (json !== null) {
            try {
                const jsonConvert: JsonConvert = new JsonConvert();
                jsonConvert.ignorePrimitiveChecks = false;
                jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
                //jsonConvert.operationMode = OperationMode.LOGGING;
                result = jsonConvert.deserialize(json, classReference);
            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        return result;
    }

    public static deserializeRegisteredClass(className: string, json: any): any | any[] {
        let result: any | any[] = null;
        if (json !== null) {
            try {
                const jsonConvert: JsonConvert = new JsonConvert();
                jsonConvert.ignorePrimitiveChecks = false;
                jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
                //jsonConvert.operationMode = OperationMode.LOGGING;
                result = jsonConvert.deserialize(json, this.registryClasses.getValue(className));
            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        return result;
    }

    public static getClass(className: string): any {
        return this.registryClasses.getValue(className);
    }

    public static getType(typeName: string): any {
        return this.registryTypes.getValue(typeName);
    }

    public static registerClass(className: string, classReference: { new(): any; }): void {
        this.registryClasses.add(className, classReference);
    }

    public static registerType(typeName: string, type: any): void {
        this.registryTypes.add(typeName, type);
        const s: string = type;
    }

    public static serialize<T>(data: T | T[]): any | any[] {
        let result: any = null;
        if (data !== null) {
            try {
                const jsonConvert: JsonConvert = new JsonConvert();
                jsonConvert.ignorePrimitiveChecks = false;
                jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
                //jsonConvert.operationMode = OperationMode.LOGGING;
                result = jsonConvert.serialize(data);
            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        return result;
    }

}
