import {JsonObject, JsonProperty} from 'json2typescript';
import {ModelEntity} from 'src/core/pin.core';
import {IModelMailing} from '../interfaces/model.mailing.interface';
import {IModelMailingState} from '../interfaces/model.mailing.state.interface';
import {ModelMailingState} from './model.mailing.state';

// @dynamic
@JsonObject('ModelMailing')
export class ModelMailing extends ModelEntity implements IModelMailing {

    @JsonProperty('barcode', String, true)
    public barcode?: string = null;
    @JsonProperty('states', [ModelMailingState], true)
    public states?: IModelMailingState[] = [];

    constructor(initValues: Partial<IModelMailing> = null) {
        super();

        if (initValues !== null) {
            Object.assign(this, initValues);
        }
    }

}
