import {Injectable} from '@angular/core';
import * as signalR from '@microsoft/signalr';
import {BehaviorSubject, Subscription} from 'rxjs';

import {ConfigService, IUtilsDictionary, LoggingService, UtilsDictionary, UtilsMisc, UtilsString} from 'src/core/pin.core';
import {BaseService} from 'src/core/pin.core/services/misc/base.service';
import {IPinSignalRClientService, SignalRConnectionState} from './pin.signalr.client.service.interface';

@Injectable({
    providedIn: 'root'
})
export class PinSignalRClientService extends BaseService implements IPinSignalRClientService {

    protected _connection: signalR.HubConnection = null;
    protected _connectionState: SignalRConnectionState = SignalRConnectionState.Closed;
    protected _isConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected _listeners: IUtilsDictionary<(message: any) => void> = null;
    protected _url: string = null;

    constructor(configurationService: ConfigService, loggingService: LoggingService) {
        super(configurationService, loggingService);

        this.loggingService.debug('PinSignalRClientService --> constructor()');
    }

    public get connectionState(): SignalRConnectionState {
        return this._connectionState;
    }

    public get isConnected(): boolean {
        return this.isConnected$.getValue();
    }

    public get url(): string {
        return this.configurationService.configData.urlHub;
        //return UtilsString.stringFormat('{0}://{1}:{2}/{3}', this.configurationService.configData.protocol, this.configurationService.configData.ip, this.configurationService.configData.port, this.configurationService.configData.urlHub);
    }

    public async closeConnectionAsync(): Promise<void> {
        try {
            this.setConnectionState(SignalRConnectionState.CloseRequest);
            if (this.connection.state === signalR.HubConnectionState.Connected) {
                await this.connection.stop();
            }
            this.setConnectionState(SignalRConnectionState.Closed);
        } catch (error) {
            this.loggingService.error(error);
        }
    }

    public async startConnectionAsync(): Promise<void> {
        try {
            this.setConnectionState(SignalRConnectionState.StartRequest);
            if (this.connection.state !== signalR.HubConnectionState.Connected) {
                await this.connection.start();
            }
            this.setConnectionState(SignalRConnectionState.Started);
        } catch (error) {
            this.loggingService.error(error);
            setTimeout(async () => {
                if (this.connectionState === SignalRConnectionState.StartRequest) {
                    await this.startConnectionAsync();
                }
            }, 10000);
        }
    }

    public addListener(messageName: string, callback: (arg: any) => void): void {
        this.listeners.set(messageName, callback);
        if (this.connection !== null) {
            this.connection.on(messageName, callback);
        }
    }

    public clearListeners(): void {
        if (this._connection !== null) {
            this.listeners.keys.forEach(key => {
                try {
                    this.connection.off(key);
                } catch (error) {
                    this.loggingService.error(error);
                }
            });
        }
        this.listeners.clear();
    }

    public async sendMessageAsync(methodName: string, ...args: any[]): Promise<any> {
        let result: any = null;

        try {
            if ((UtilsMisc.isNullOrUndefined(args) === false) && (args.length > 0)) {
                result = await this.connection.invoke(methodName, args);
            } else {
                result = await this.connection.invoke(methodName);
            }
        } catch (error) {
            this.loggingService.error(error);
        }

        return result;
    }

    public subscribeForConnectionChange(method: (value: boolean) => void): Subscription {
        return this.isConnected$.subscribe(value => method(value));
    }

    protected get connection(): signalR.HubConnection {
        if (this._connection === null) {
            this._connection = this.doCreateConnection();
        }

        return this._connection;
    }

    protected get isConnected$(): BehaviorSubject<boolean> {
        return this._isConnected$;
    }

    protected get listeners(): IUtilsDictionary<(message: any) => void> {
        if (this._listeners === null) {
            this._listeners = new UtilsDictionary<(message: any) => void>();
        }

        return this._listeners;
    }

    protected doCreateConnection(): signalR.HubConnection {
        if (this._connection === null) {
            this._connection = new signalR.HubConnectionBuilder()
                .withUrl(this.url)
                .configureLogging(signalR.LogLevel.Information)
                .build();

            //this.connection.serverTimeoutInMilliseconds = 3000;

            this._connection.onclose(() => {
                if ((this.connectionState !== SignalRConnectionState.CloseRequest) && (this.connectionState !== SignalRConnectionState.Closed)) {
                    this.setConnectionState(SignalRConnectionState.Reconnecting);
                    setTimeout(async () => {
                        if ((this.connectionState !== SignalRConnectionState.CloseRequest) && (this.connectionState !== SignalRConnectionState.Closed)) {
                            await this.startConnectionAsync();
                        }
                    }, 10000);
                } else {
                    this.setConnectionState(SignalRConnectionState.Closed);
                }
            });
        }

        return this._connection;
    }

    protected setConnectionState(value: SignalRConnectionState): void {
        if (value !== this.connectionState) {
            this._connectionState = value;
            this.isConnected$.next(this.connectionState === SignalRConnectionState.Started);
        }
    }

}
