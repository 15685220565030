import {Injectable} from '@angular/core';
import {ConfigService, IModelEntity, IUtilsDictionary, LoggingService, UtilsDictionary, UtilsMisc, UtilsString} from 'src/core/pin.core';
import {BaseService} from 'src/core/pin.core/services/misc/base.service';
import {IEntityDataService} from 'src/core/pin.data';
import {IEntityDataServiceAccessorService} from '../interfaces/entity.data.service.accessor.service.interface';

@Injectable({
    providedIn: 'root'
})
export class EntityDataServiceAccessorService extends BaseService implements IEntityDataServiceAccessorService {

    protected _entityServices: IUtilsDictionary<any> = null;

    constructor(configService: ConfigService, loggingService: LoggingService) {
        super(configService, loggingService);

        loggingService.debug('EntityDataServiceAccessorService --> constructor()');
    }

    public getEntityDataService<T extends IModelEntity>(serviceName: string): IEntityDataService<T> {
        let result: IEntityDataService<T> = null;

        if (UtilsString.stringIsNullOrEmpty(serviceName) === false) {
            result = (this.entityServices.getValue(serviceName) as IEntityDataService<T>);
        }

        return result;
    }

    public registerEntityDataService<T extends IModelEntity>(serviceName: string, entityDataService: IEntityDataService<T>) {
        if ((UtilsString.stringIsNullOrEmpty(serviceName) === false) && (UtilsMisc.isNullOrUndefined(entityDataService) === false)) {
            this.entityServices.add(serviceName, entityDataService);
        }
    }

    protected get entityServices(): IUtilsDictionary<any> {
        if (this._entityServices === null) {
            this._entityServices = new UtilsDictionary<any>();
        }

        return this._entityServices;
    }

}
