import {AfterViewInit, Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {IApplicationService} from '../application/interfaces/application.service.interface';

import {IConfigService} from '../configuration/services/config.service';
import {ILoggingService} from '../logging/logging.service';
import {UtilsMisc} from '../utils/utils.misc';

@Directive()
export abstract class BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    protected unsubscribe$ = new Subject<void>();

    constructor(protected applicationService: IApplicationService) {
    }

    protected _activatedSubject: BehaviorSubject<any> = null;

    @Input()
    public get activatedSubject(): BehaviorSubject<any> {
        return this._activatedSubject;
    }

    public set activatedSubject(value: BehaviorSubject<any>) {
        if (value !== this.activatedSubject) {
            this._activatedSubject = value;

            if (UtilsMisc.isNullOrUndefined(this.activatedSubject) === false) {
                this.activatedSubject.subscribe(async () => {
                    await this.doRefreshAsync();
                });
            }
        }
    }

    public get platformMode(): string {
        return this.applicationService.platformMode;
    }

    protected _subscriptions: Subscription[] = null;

    protected get subscriptions(): Subscription[] {
        if (this._subscriptions === null) {
            this._subscriptions = [];
        }

        return this._subscriptions;
    }

    protected get configService(): IConfigService {
        return this.applicationService.getConfigurationService();
    }

    protected get loggingService(): ILoggingService {
        return this.applicationService.getLoggingService();
    }

    public async ngAfterViewInit(): Promise<void> {
        await this.doOnAfterViewInitAsync();
    }

    public async ngOnDestroy(): Promise<void> {
        await this.doOnDestroyAsync();
    }

    public async ngOnInit(): Promise<void> {
        await this.doOnInitAsync();
    }

    protected async doOnAfterViewInitAsync(): Promise<void> {
    }

    protected async doOnDestroyAsync(): Promise<void> {
        this.unsubscribe();

        if (this._subscriptions !== null) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    protected async doOnInitAsync(): Promise<void> {
    }

    protected async doRefreshAsync(): Promise<void> {
    }

    protected unsubscribe(): void {
        if (this.unsubscribe$ !== null) {
            this.unsubscribe$.next();
            this.unsubscribe$.complete();
            this.unsubscribe$ = null;
        }
    }

}
