import {Subscription} from 'rxjs';
import {IBaseService} from 'src/core/pin.core/services/misc/base.service';

export enum SignalRConnectionState {

    StartRequest = 0,
    Started = 1,
    Reconnecting = 2,
    CloseRequest = 3,
    Closed = 4

}

export interface IPinSignalRClientService extends IBaseService {

    connectionState: SignalRConnectionState;
    isConnected: boolean;
    url?: string;

    closeConnectionAsync(): Promise<void>;

    startConnectionAsync(): Promise<void>;

    addListener(messageName: string, callback: (...args: any[]) => void): void;

    clearListeners(): void;

    sendMessageAsync(methodName: string, ...args: any[]): Promise<any>;

    subscribeForConnectionChange(method: (value: boolean) => void): Subscription;

}
