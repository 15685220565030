import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ApplicationService, PageBaseComponent} from 'src/core/pin.core';

@Component({
    selector: 'pit-track-web-pit.track.web.show.error.page',
    templateUrl: './pit.track.web.show.error.page.component.html',
    styleUrls: ['./pit.track.web.show.error.page.component.scss']
})
export class PITTrackWebShowErrorPageComponent extends PageBaseComponent {

    @Input()
    public errorMessages: string[] = [];

    constructor(applicationService: ApplicationService, private modalController: ModalController) {
        super(applicationService);
    }

    public async cancelAsync(): Promise<void> {
        await this.modalController.dismiss();
    }

}
