import {Component} from '@angular/core';
import {ApplicationService} from 'src/core/pin.core';
import {AppComponentBase} from 'src/core/pin.core/components/app.component.base';
import {DesktopService, IAppPage} from 'src/core/pin.core/application/services/desktop.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent extends AppComponentBase {

    public isBusy: boolean = false;

    constructor(applicationService: ApplicationService, desktopService: DesktopService) {
        super(desktopService, applicationService);
    }

    public async navigateToAsync(appPage: IAppPage) {
        this.desktopService.selectedPage = appPage;
        await this.applicationService.navigateToAsync(appPage.url);
    }

    protected async doInitializeAppAsync(): Promise<void> {
        await super.doInitializeAppAsync();

        this.applicationService.subscribeForBusyChange((value: boolean) => {
            this.isBusy = value;
        });
    }

    protected async doOnAfterViewInitAsync(): Promise<void> {
        await super.doOnAfterViewInitAsync();
    }

    protected async doOnInitAsync(): Promise<void> {
        await super.doOnInitAsync();
    }

    protected async doOnDestroyAsync(): Promise<void> {
        await super.doOnDestroyAsync();
    }

}
