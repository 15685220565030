import {UtilsMisc} from '../../utils/utils.misc';

export interface IConfigAdmin {

    ip: string;
    port: string;
    protocol: string;
    timeout: number;
    urlApi: string;
    urlHub: string;

}

// @dynamic
export class ConfigAdmin {

    private static _ip: string = 'localhost';

    public static get ip(): string {
        return ConfigAdmin._ip;
    }

    private static _port: string = '5001';

    public static get port(): string {
        return ConfigAdmin._port;
    }

    private static _protocol: string = 'http';

    public static get protocol(): string {
        return ConfigAdmin._protocol;
    }

    private static _timeout: number = 2000;

    public static get timeout(): number {
        return ConfigAdmin._timeout;
    }

    private static _urlApi: string = 'api';

    public static get urlApi(): string {
        return ConfigAdmin._urlApi;
    }

    private static _urlHub: string = 'hub';

    public static get urlHub(): string {
        return ConfigAdmin._urlHub;
    }

    public static assign(source: IConfigAdmin): void {
        if (UtilsMisc.isNullOrUndefinedOrEmpty(source) === false) {
            ConfigAdmin._ip = source.ip;
            ConfigAdmin._port = source.port;
            ConfigAdmin._protocol = source.protocol;
            ConfigAdmin._timeout = source.timeout;
            ConfigAdmin._urlApi = source.urlApi;
            ConfigAdmin._urlHub = source.urlHub;
        }
    }

}
