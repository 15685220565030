import {Injectable, Injector} from '@angular/core';

import {LoggingService} from '../../logging/logging.service';

@Injectable({
    providedIn: 'root'
})
export class StaticInjectorService {

    protected static _injector: Injector = null;

    constructor(logger: LoggingService) {
        logger.debug('StaticInjectorService --> constructor()');
    }

    public static getInjector(): Injector {
        return StaticInjectorService._injector;
    }

    public static setInjector(value: Injector) {
        StaticInjectorService._injector = value;
    }

}
