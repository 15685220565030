import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigService, IUtilsDictionary, LoggingService, UtilsJson, UtilsMisc, UtilsString} from 'src/core/pin.core';
import {EntityDataService} from 'src/core/pin.data';
import {ModelMailing} from '../../model/implementations/model.mailing';
import {IModelMailing} from '../../model/interfaces/model.mailing.interface';
import {IModelMailingEntityDataService} from '../interfaces/model.mailing.entity.data.service.interface';

@Injectable({
    providedIn: 'root'
})
export class ModelMailingEntityDataService extends EntityDataService<IModelMailing> implements IModelMailingEntityDataService {

    constructor(http: HttpClient, configService: ConfigService, loggingService: LoggingService) {
        super(http, null, configService, loggingService);
    }

    public getMailingByBarcodeAndZipCode$(barcode: string, zipCode: string): Observable<IModelMailing> {
        return this.httpGet(this.createUrlForQuery({query: 'getbybarcodezipcode', urlParams: [barcode, zipCode]}))
            .pipe(map(entity => {
                if (UtilsMisc.isNullOrUndefinedOrEmpty(entity) === false) {
                    return this.createEntityFromJson(entity);
                } else {
                    return null;
                }
            }));
    }

    public async getMailingByBarcodeAndZipCodeAsync(barcode: string, zipCode: string): Promise<IModelMailing> {
        return (await this.getMailingByBarcodeAndZipCode$(barcode, zipCode).toPromise());
    }

    protected doGetApiUrl(): string {
        return 'api';
    }

    protected createEntityFromJson(json: any): IModelMailing {
        const result: IModelMailing = (UtilsJson.deserialize(json, ModelMailing) as IModelMailing);
        if (result !== null) {
            result.setReferences();
        }

        return result;
    }

    protected doGetUrls(): IUtilsDictionary<string> {
        this._urls = super.doGetUrls();

        this._urls.add('getbybarcodezipcode', UtilsString.stringFormat('{0}://{1}:{2}/{3}/search/', this.configurationService.configData.protocol, this.configurationService.configData.ip, this.configurationService.configData.port, this.apiUrl) + '{0}/{1}');
        this._urls.add('setstates', UtilsString.stringFormat('{0}://{1}:{2}/{3}/states/', this.configurationService.configData.protocol, this.configurationService.configData.ip, this.configurationService.configData.port, this.apiUrl));

        return this._urls;
    }

}
