import {JsonObject, JsonProperty} from 'json2typescript';
import {ModelEntity, UtilsJsonConverterDate} from 'src/core/pin.core';
import {IModelMailingState} from '../interfaces/model.mailing.state.interface';
import {IModelMailingStateType} from '../interfaces/model.mailing.state.type.interface';
import {ModelMailingStateType} from './model.mailing.state.type';

// @dynamic
@JsonObject('ModelMailingState')
export class ModelMailingState extends ModelEntity implements IModelMailingState {

    @JsonProperty('timestamp', UtilsJsonConverterDate, true)
    public timestamp: Date = null;
    @JsonProperty('type', ModelMailingStateType, true)
    public type: IModelMailingStateType = null;

    constructor(initValues: Partial<IModelMailingState> = null) {
        super();

        if (initValues !== null) {
            Object.assign(this, initValues);
        }
    }

}
