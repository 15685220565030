import {JsonConverter, JsonCustomConvert} from 'json2typescript';

import {UtilsDate} from './utils.date';

@JsonConverter
export class UtilsJsonConverterDate implements JsonCustomConvert<Date> {

    public serialize(date: Date): any {
        return UtilsDate.dateTimeToString(date);
    }

    public deserialize(date: any): Date {
        return UtilsDate.dateParseFromString(date);
    }

}
