import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LoggingService} from '../logging/logging.service';


@NgModule({
    imports: [
        CommonModule
    ]
})
export class PinCoreModule {

    constructor(logger: LoggingService) {
        logger.debug('PinCoreModule --> constructor()');
    }

    public static forRoot(): ModuleWithProviders<PinCoreModule> {
        return {
            ngModule: PinCoreModule,
            providers: []
        };
    }

}
