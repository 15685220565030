import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {EMPTY, empty, Observable, of, throwError} from 'rxjs';
import {retryWithDelay} from 'rxjs-boost/lib/operators';
import {catchError, map, timeout} from 'rxjs/operators';
import {IConfigService, ILoggingService, IModelEntity, IUtilsDictionary, UtilsDictionary, UtilsMisc, UtilsString} from 'src/core/pin.core';
import {BaseService} from 'src/core/pin.core/services/misc/base.service';
import {IEntityDataService, IListResult, RequestParams} from '../interfaces/entity.data.service.interface';
import {ILocalStorageProvider} from '../interfaces/local.storage.provider.interface';

export enum SaveType {

    Local = 0,
    Remote = 1

}

export class EntityDataService<T extends IModelEntity> extends BaseService implements IEntityDataService<T> {

    public static saveType: SaveType = SaveType.Remote;
    protected http: HttpClient = null;
    protected localStorage: ILocalStorageProvider<T> = null;

    constructor(http: HttpClient, localStorage: ILocalStorageProvider<T>, configService: IConfigService, loggingService: ILoggingService) {
        super(configService, loggingService);

        this.http = http;
        this.localStorage = localStorage;

        this.loggingService.debug('EntityDataService --> constructor()');
    }

    public get apiUrl(): string {
        return this.doGetApiUrl();
    }

    protected _urls: IUtilsDictionary<string> = null;

    protected get urls(): IUtilsDictionary<string> {
        return this.doGetUrls();
    }

    public getAll(requestParams: RequestParams = null): Observable<IListResult<T>> {
        return this.doGetAll(requestParams);
    }

    public getById(id: number, params: string[] = null): Observable<T> {
        return this.doGetById(id, params);
    }

    public getByQuery(requestParams: RequestParams): Observable<IListResult<T>> {
        return this.doGetByQuery(requestParams);
    }

    public save(entity: T): Observable<T> {
        return this.doSave(entity);
    }

    public saveMulti(entities: T[]): Observable<boolean> {
        return this.doSaveMulti(entities);
    }

    public async getAllAsync(requestParams: RequestParams = null): Promise<IListResult<T>> {
        let result: IListResult<T> = null;
        try {
            result = await this.getAll(requestParams).toPromise();
            if (UtilsMisc.isNullOrUndefinedOrEmpty(result) === true) {
                result = null;
            }
        } catch (error) {
            this.loggingService.error(error);
        }

        return result;
    }

    public async getByIdAsync(id: number, params: string[] = null): Promise<T> {
        let result: T = await this.getById(id, params).toPromise();
        if (UtilsMisc.isNullOrUndefinedOrEmpty(result) === true) {
            result = null;
        }

        return result;
    }

    public async getByQueryAsync(requestParams: RequestParams): Promise<IListResult<T>> {
        let result: IListResult<T> = await this.getByQuery(requestParams).toPromise();
        if (UtilsMisc.isNullOrUndefinedOrEmpty(result) === true) {
            result = null;
        }

        return result;
    }

    public async saveAsync(entity: T): Promise<T> {
        let result: T = await this.save(entity).toPromise();
        if (UtilsMisc.isNullOrUndefinedOrEmpty(result) === true) {
            result = null;
        }

        return result;
    }

    public async saveMultiAsync(entities: T[]): Promise<boolean> {
        return (await this.saveMulti(entities).toPromise());
    }

    protected doGetApiUrl(): string {
        return null;
    }

    protected doGetAll(requestParams: RequestParams = null): Observable<IListResult<T>> {
        let result$: Observable<IListResult<T>> = EMPTY;

        switch (EntityDataService.saveType) {
            case SaveType.Local: {
                result$ = this.doGetAllLocal();
                break;
            }
            case SaveType.Remote: {
                if (UtilsMisc.isNullOrUndefined(requestParams) === true) {
                    requestParams = {};
                }
                requestParams.query = 'getall';

                result$ = this.doGetAllRemote(requestParams);
                break;
            }
        }

        return result$;
    }

    protected doGetAllLocal(): Observable<IListResult<T>> {
        let result$: Observable<IListResult<T>> = EMPTY;

        if (this.localStorage !== null) {
            result$ = this.localStorage.getAll();
        }

        return result$;
    }

    protected doGetAllRemote(requestParams: RequestParams): Observable<IListResult<T>> {
        return this.httpGet(this.createUrlForQuery(requestParams))
            .pipe(map((queryResult: IListResult<T>) => {
                const result: IListResult<T> = {
                    count: 0,
                    items: []
                };
                const items: T[] = [];
                if ((UtilsMisc.isNullOrUndefined(queryResult) === false) && (UtilsMisc.isNullOrUndefined(queryResult.items) === false) &&
                    (queryResult.items.length > 0)) {
                    for (const entity of queryResult.items) {
                        items.push(this.createEntityFromJson(entity));
                    }
                    result.count = queryResult.count;
                    result.items = items;
                }
                return result;
            }));
    }

    protected doGetById(id: number, params: string[] = null): Observable<T> {
        let result$: Observable<T> = EMPTY;

        switch (EntityDataService.saveType) {
            case SaveType.Local: {
                result$ = this.doGetByIdLocal(id);
                break;
            }
            case SaveType.Remote: {
                if (params === null) {
                    params = [];
                }
                params.push(id.toString());

                result$ = this.doGetByIdRemote(id, params);
                break;
            }
        }

        return result$;
    }

    protected doGetByIdLocal(id: number): Observable<T> {
        let result$: Observable<T> = EMPTY;

        if (this.localStorage !== null) {
            result$ = this.localStorage.getById(id);
        }

        return result$;
    }

    protected doGetByIdRemote(id: number, params: string[]): Observable<T> {
        return this.httpGet(this.createUrlForQuery({query: 'getbyid', urlParams: params}))
            .pipe(map(entity => {
                if (UtilsMisc.isNullOrUndefinedOrEmpty(entity) === false) {
                    return this.createEntityFromJson(entity);
                } else {
                    return null;
                }
            }));

    }

    protected doGetByQuery(requestParams: RequestParams): Observable<IListResult<T>> {
        let result$: Observable<IListResult<T>> = empty();
        if ((UtilsMisc.isNullOrUndefinedOrEmpty(requestParams) === false) && (UtilsMisc.isNullOrUndefinedOrEmpty(requestParams.query) === false)) {
            const transactionId: string = ((UtilsMisc.isNullOrUndefinedOrEmpty(requestParams.transactionId) === false) ? requestParams.transactionId.toString() : null);
            const query: string = requestParams.query.toString().toLowerCase();
            switch (query) {
                /*case 'committransaction': {
                    if (UtilsString.stringIsNullOrEmpty(transactionId) === false) {
                        result$ = this.transactionService.commitTransaction(this.entityName, params.transactionId.toString()).pipe(map(transaction => {
                            return ([transaction] as any[]);
                        }));
                    }
                    break;
                }
                case 'rollbacktransaction': {
                    if (UtilsString.stringIsNullOrEmpty(transactionId) === false) {
                        result$ = this.transactionService.rollbackTransaction(this.entityName, params.transactionId.toString()).pipe(map(transaction => {
                            return ([transaction] as any[]);
                        }));
                    }
                    break;
                }
                case 'starttransaction': {
                    result$ = this.transactionService.startTransaction(this.entityName).pipe(map(transaction => {
                        return ([transaction] as any[]);
                    }));
                    break;
                }*/
                default: {
                    result$ = this.httpGet(this.createUrlForQuery(requestParams), transactionId)
                        .pipe(map((queryResult: IListResult<T>) => {
                            const result: IListResult<T> = {
                                count: 0,
                                items: null
                            };
                            const items: T[] = [];
                            if ((UtilsMisc.isNullOrUndefinedOrEmpty(queryResult) === false) && (UtilsMisc.isNullOrUndefined(queryResult.items) === false) &&
                                (queryResult.items.length > 0)) {
                                for (const entity of queryResult.items) {
                                    items.push(this.createEntityFromJson(entity));
                                }
                                result.count = queryResult.count;
                                result.items = items;
                            }
                            return result;
                        }));
                    break;
                }
            }
        }

        return result$;
    }

    protected doSave(entity: T): Observable<T> {
        let result$: Observable<T> = EMPTY;

        switch (EntityDataService.saveType) {
            case SaveType.Local: {
                result$ = this.doSaveLocal(entity);
                break;
            }
            case SaveType.Remote: {
                result$ = this.doSaveRemote(entity);
                break;
            }
        }

        return result$;
    }

    protected doSaveLocal(entity: T): Observable<T> {
        let result$: Observable<T> = null;

        if (this.localStorage !== null) {
            result$ = this.localStorage.save(entity);
        } else {
            result$ = of(entity);
        }

        return result$;
    }

    protected doSaveRemote(entity: T): Observable<T> {
        const entityToSave: T = (entity.clone() as T);
        entityToSave.removeReferences();
        if ((entityToSave.id === null) || (entityToSave.id <= 0)) {
            entityToSave.id = null;
        }

        return this.httpPost(this.createUrlForQuery({query: 'save'}), entityToSave, entityToSave.transactionId)
            .pipe(map(entity => {
                if (UtilsMisc.isNullOrUndefinedOrEmpty(entity) === false) {
                    return this.createEntityFromJson(entity);
                } else {
                    return null;
                }
            }));
    }

    protected doSaveMulti(entities: T[]): Observable<boolean> {
        let result$: Observable<boolean> = of(false);

        switch (EntityDataService.saveType) {
            case SaveType.Local: {
                result$ = this.doSaveMultiLocal(entities);
                break;
            }
            case SaveType.Remote: {
                result$ = this.doSaveMultiRemote(entities);
                break;
            }
        }

        return result$;
    }

    protected doSaveMultiLocal(entities: T[]): Observable<boolean> {
        let result$: Observable<boolean> = of(false);

        if (this.localStorage !== null) {
            this.localStorage.saveMulti(entities);
            result$ = of(true);
        }

        return result$;
    }

    protected doSaveMultiRemote(entities: T[]): Observable<boolean> {
        let result$: Observable<boolean> = of(false);

        const entitiesToSave: T[] = [];
        for (let loop = 0; loop < entities.length; loop++) {
            const entityToSave: T = (entities[loop].clone() as T);
            entityToSave.removeReferences();
            if ((entityToSave.id === null) || (entityToSave.id <= 0)) {
                entityToSave.id = null;
            }
            entitiesToSave.push(entityToSave);
        }

        return this.httpPost(this.createUrlForQuery({query: 'savemulti'}), entitiesToSave, entitiesToSave[0].transactionId)
            .pipe(map((value: boolean) => {
                return value;
            }));
    }

    protected doGetHttpOptions(transactionId: string = null): any {
    }

    protected doGetUrls(): IUtilsDictionary<string> {
        if (this._urls === null) {
            this._urls = new UtilsDictionary<string>();

            this._urls.add('getall', UtilsString.stringFormat('{0}://{1}:{2}/{3}/all', this.configurationService.configData.protocol, this.configurationService.configData.ip, this.configurationService.configData.port, this.apiUrl));
            this._urls.add('getbyid', UtilsString.stringFormat('{0}://{1}:{2}/{3}/id/', this.configurationService.configData.protocol, this.configurationService.configData.ip, this.configurationService.configData.port, this.apiUrl) + '{0}');
            this._urls.add('save', UtilsString.stringFormat('{0}://{1}:{2}/{3}/save', this.configurationService.configData.protocol, this.configurationService.configData.ip, this.configurationService.configData.port, this.apiUrl));
            this._urls.add('savemulti', UtilsString.stringFormat('{0}://{1}:{2}/{3}/savemulti', this.configurationService.configData.protocol, this.configurationService.configData.ip, this.configurationService.configData.port, this.apiUrl));
        }

        return this._urls;
    }

    protected createJsonFromEntity(entity: T): T {
        return null;
    }

    protected createEntityFromJson(json: any): T {
        return json;
    }

    protected createUrlForQuery(requestParams: RequestParams = null): string {
        let result: string = this.urls.getValue(requestParams.query);
        let params: string[] = [];
        if (UtilsString.stringIsNullOrEmpty(result) === false) {
            if ((UtilsMisc.isNullOrUndefined(requestParams) === false) && (UtilsMisc.isNullOrUndefined(requestParams.urlParams) === false) && (requestParams.urlParams.length > 0)) {
                params = requestParams.urlParams;
            }
            //params.unshift(this.apiUrl);
            result = UtilsString.stringFormat(result, params);

            if (UtilsMisc.isNullOrUndefined(requestParams.startRow) === false) {
                result = result + '/' + requestParams.startRow.toString();
            }
            if (UtilsMisc.isNullOrUndefined(requestParams.rowsToTake) === false) {
                result = result + '/' + requestParams.rowsToTake.toString();
            }
        }

        return result;
    }

    protected handleHttpError(error: HttpErrorResponse): Observable<never> {
        this.loggingService.error(error);
        return throwError(error);
    }

    protected httpGet(url: string, transactionId: string = null, timeoutInMilliSeconds: number = null, maxRetries: number = null, retryDelay: number = null): Observable<any> {
        if (UtilsMisc.isNullOrUndefined(timeoutInMilliSeconds) === true) {
            timeoutInMilliSeconds = this.configurationService.configData.timeout;
        }
        if (UtilsMisc.isNullOrUndefined(maxRetries) === true) {
            maxRetries = this.configurationService.configData.retries;
        }
        if (UtilsMisc.isNullOrUndefined(retryDelay) === true) {
            retryDelay = this.configurationService.configData.retryDelay;
        }
        return this.http.get(url, this.doGetHttpOptions(transactionId)).pipe(
            timeout(timeoutInMilliSeconds),
            catchError(this.handleHttpError.bind(this)),
            retryWithDelay(retryDelay, maxRetries)
        );
    }

    protected httpPost(url: string, body: any, transactionId: string = null, timeoutInMilliSeconds: number = null, maxRetries: number = null, retryDelay: number = null): Observable<any> {
        if (UtilsMisc.isNullOrUndefined(timeoutInMilliSeconds) === true) {
            timeoutInMilliSeconds = this.configurationService.configData.timeout;
        }
        if (UtilsMisc.isNullOrUndefined(maxRetries) === true) {
            maxRetries = this.configurationService.configData.retries;
        }
        if (UtilsMisc.isNullOrUndefined(retryDelay) === true) {
            retryDelay = this.configurationService.configData.retryDelay;
        }
        return this.http.post(url, body, this.doGetHttpOptions(transactionId)).pipe(
            timeout(timeoutInMilliSeconds),
            catchError(this.handleHttpError.bind(this)),
            retryWithDelay(retryDelay, maxRetries)
        );
    }

}
