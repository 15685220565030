import {UtilsMisc} from '../../utils/utils.misc';

export interface IConfigData {

    ip: string;
    port: string;
    protocol: string;
    retries: number;
    retryDelay: number;
    timeout: number;
    urlApi: string;
    urlHub: string;

}

// @dynamic
export class ConfigData {

    private static _ip: string = 'localhost';

    public static get ip(): string {
        return ConfigData._ip;
    }

    public static set ip(value: string) {
        ConfigData._ip = value;
    }

    private static _port: string = '5001';

    public static get port(): string {
        return ConfigData._port;
    }

    private static _protocol: string = 'http';

    public static get protocol(): string {
        return ConfigData._protocol;
    }

    private static _retries: number = 3;

    public static get retries(): number {
        return ConfigData._retries;
    }

    private static _retryDelay: number = 1000;

    public static get retryDelay(): number {
        return ConfigData._retryDelay;
    }

    private static _timeout: number = 2000;

    public static get timeout(): number {
        return ConfigData._timeout;
    }

    private static _urlApi: string = 'api';

    public static get urlApi(): string {
        return ConfigData._urlApi;
    }

    private static _urlHub: string = 'hub';

    public static get urlHub(): string {
        return ConfigData._urlHub;
    }

    public static set urlHub(value: string) {
        ConfigData._urlHub = value;
    }

    public static assign(source: IConfigData): void {
        if (UtilsMisc.isNullOrUndefinedOrEmpty(source) === false) {
            ConfigData._ip = source.ip;
            ConfigData._port = source.port;
            ConfigData._protocol = source.protocol;
            ConfigData._retries = source.retries;
            ConfigData._retryDelay = source.retryDelay;
            ConfigData._timeout = source.timeout;
            ConfigData._urlApi = source.urlApi;
            ConfigData._urlHub = source.urlHub;
        }
    }

}
