import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ConfigService, ErrorService, LoggingService} from 'src/core/pin.core';
import {PITTrackWebShowErrorPageComponent} from '../../pages/pit.track.web.show.error.page/pit.track.web.show.error.page.component';
import {IPITTrackWebErrorService} from '../interfaces/pit.track.web.error.service.interface';

@Injectable({
    providedIn: 'root'
})
export class PITTrackWebErrorService extends ErrorService implements IPITTrackWebErrorService {

    constructor(configurationService: ConfigService, loggingService: LoggingService, private modalController: ModalController) {
        super(configurationService, loggingService);

        this.loggingService.debug('PITTrackErrorService --> constructor()');
    }

    protected async doShowErrorAsync(errorMessages: string[]): Promise<void> {
        const pageShowError: HTMLIonModalElement = await this.modalController.create({
            component: PITTrackWebShowErrorPageComponent,
            backdropDismiss: false,
            showBackdrop: true,
            cssClass: 'dialog-error-show',
            swipeToClose: false,
            componentProps: {
                'errorMessages': errorMessages
            }
        });
        await pageShowError.present();
    }

}
